<template>
  <Dialog
    v-model:visible="showEmailDialog"
    :style="{ width: '650px' }"
    header="Document Email"
    :modal="true"
    class="theme-dialog"
    maximizable
    @hide="closeEmailDialogue()"
  >
    <div class="document-email flex flex-column">
      <div class="w-12 flex flex-column">

        <!-- Using .flex with .grid is required because TailwindCSS .grid applies "display: grid" -->

        <!-- To -->
        <div class="grid flex align-items-center my-1 mx-0" :class="this.emtpyEmailToError ? 'deerror' : ''">
          <label class="col-fixed label">To</label>
          <Dropdown v-if="type=='RCON'"
            v-model="selectedEmail"
            :options="emaillist"
            :style="'padding:0px'"
            class="col form-control border-control documentemail-dropdown"
            placeholder="Select"
            @change="changeSelectedEmail($event)"
          />
          <Chips
          v-else
          v-model="selectedEmail"
          @add="validateEmailList('to')"
          @remove="removeEmail($event,'to')"
          separator=" "
          :class="this.emtpyEmailToError &&(!selectedEmail || selectedEmail.length==0)?'chipError':''"
          class="col border-control p-0"
          :max="50"
          :allowDuplicate="false"
          addOnBlur=true
          aria-autocomplete="false"   
          aria-describedby="help-text"
          >
          <template #chip="slotProps">
            <div v-if="!isEmailValid(slotProps.value)">
              <span class="text-red-500">{{ slotProps.value }} </span>
              <i class="ml-1 pi pi-exclamation-circle text-red-500" style="font-size: 14px" v-tooltip.right="{ value: 'Invalid Email.', class: 'tooltip-features' }"></i>
            </div>
          </template>
        </Chips>
        </div>
        <!-- CC -->
        <div class="grid flex align-items-center my-1 mx-0">
          <label class="col-fixed label label-align">CC</label>
            <Chips
              v-model="DraftEmail.EmailCC"
              @add="validateEmailList('cc')"
              @remove="removeEmail($event,'cc')"
              separator=" "
              class="col border-control p-0"
              aria-autocomplete="false"
              :max="50"
              :allowDuplicate="false"  
              addOnBlur=true
              aria-describedby="help-text"
          >
          <template #chip="slotProps">
            <div v-if="!isEmailValid(slotProps.value)">
              <span class="text-red-500">{{ slotProps.value }} </span>
              <i class="ml-1 pi pi-exclamation-circle text-red-500" style="font-size: 14px" v-tooltip.right="{ value: 'Invalid Email.', class: 'tooltip-features' }"></i>
            </div>
          </template>
        </Chips>
        </div>
        <!-- BCC -->
        <div class="grid flex align-items-center my-1 mx-0" :class="this.invalidEmailBCCError ? 'deerror' : ''">
          <label class="col-fixed label">BCC</label>
          <Chips
            v-model="DraftEmail.EmailBCC"
            @add="validateEmailList('bcc')"
            @remove="removeEmail($event,'bcc')"
            separator=" "
            class="col border-control p-0"
            placeholder=""
            :max="50"
            :allowDuplicate="false"
            addOnBlur=true
            aria-autocomplete="false"
            aria-describedby="help-text"
          >
          <template #chip="slotProps">
            <div v-if="!isEmailValid(slotProps.value)">
              <span class="text-red-500">{{ slotProps.value }} </span>
              <i class="ml-1 pi pi-exclamation-circle text-red-500" style="font-size: 14px" v-tooltip.right="{ value: 'Invalid Email.', class: 'tooltip-features' }"></i>
            </div>
          </template>
        </Chips>
        </div>

        <!-- Subject -->
        <div class="grid flex align-items-center my-1 mx-0">
          <label class="col-fixed label">Subject</label>
          <input
            v-model="DraftEmail.Subject"
            type="text"
            class="col email-form-control border-control"
            placeholder="Enter Text"
          />
        </div>

        <!-- Attachments -->
        <div class="grid flex align-items-center my-1 mx-0">
          <label class="col-fixed label">Attachments</label>
          <input
            v-model="attachments"
            type="text"
            class="col email-form-control border-control"
            placeholder=""
            disabled
          />
        </div>
        <!--  /// Do not delete, /// will help if want to open attachment in email popup 

              <div class="w-12 mr-2 mt-2 flex">
                  <label class="w-2 label">Attachments</label>
                  <div class="attach-form-control mr-1 border-control flex">
                  <a type="text"
                  v-for="(documentEmail, index) in this.selectedDocuments" :key="index"
                      :value="documentEmail.OriginalFileName"
                      @click="getPdf(documentEmail.FullPath)"
                      class="email-docs mr-1 border-control"
                      placeholder=""
                  >{{documentEmail.OriginalFileName+", "}}</a>
                  </div>
              </div> -->
      </div>
      <div class="w-12 mt-3 flex-grow-1 flex flex-column">
        <p
          v-if="this.mailMethod == 'sendEmail'"
          class="p-textarea themeWisePickBC flex-grow-1"
          rows="5"
          placeholder=""
          v-html="DraftEmail.Body"
          maxlength="100"
          style="overflow: auto;"
        />
        <Editor
          v-else
          class="p-textarea themeWisePickBC flex-grow-1 flex flex-column"
          rows="5"
          placeholder=""
          v-model="DraftEmail.Body"
          maxlength="100"
          editorStyle="flex-grow: 1"
        />
      </div>      
      <hr class="mb-3 mt-1" />
    </div>
    <template #footer>
      <button class="theme-btn" @click="sendEmail()">Ok</button>
      <button class="btn-outline" @click="closeEmailDialogue()">Cancel</button>
    </template>
  </Dialog>
  <vue-element-loading
    :active="isActive"
    spinner="bar-fade-scale"
    color="#FF6700"
    :is-full-screen="true"
  />  
    <Toast position="bottom-right" group="de">
 <template #message="slotProps">
 <span :class="iconClass"></span>
 <div class="p-toast-message-text">
 <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
 <div class="p-toast-detail" v-html="slotProps.message.detail" />
 </div></template>
 </Toast> 
</template>

<script>
import VueElementLoading from "vue-element-loading";
import { dataProvider } from "@/api/dataProvider.js";
import Toast from "primevue/toast";
import Editor from 'primevue/editor';
import Chips from 'primevue/chips';

export default {
  props:['emaillist','type'],
  data() {
    return {
        emtpyEmailToError: false,
        isActive:false,
        showEmailDialog: false,
       DraftEmail: {
        SenderAccount: "noreply@shipldi.com",
        Subject: "",
        Body: "",
        IsHtml: true,
        FromAccount: "noreply@shipldi.com",
        EmailTo: [],
        EmailCC: [],
        EmailBCC: [],
        }, 
        attachments:[],             
      selectedDocuments: [],
      mailMethod:'',
      selectedEmail:[]
    };
  },
  mounted:function(){
    if(this.emaillist?.length > 0){
    var primaryEmail = this.emaillist[0]
    this.DraftEmail.EmailTo = []
    this.DraftEmail.EmailTo.push(primaryEmail)
    if (this.type !== 'BOL' && this.type !== 'LTLBOL' && this.type !== 'QUOTE') { // Don't set a default To email for these
      this.selectedEmail = [primaryEmail];
    }
    }else{
      this.DraftEmail.EmailTo = []
    }
  
  },
  components:{
    VueElementLoading,
    Toast,
    Editor,
    Chips
  },
  methods: {
    isEmailValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    validateEmailList(listType) {
      let emailList = [];   
      // Get the appropriate email list based on the type
      if (listType === 'to') emailList = this.selectedEmail;
      if (listType === 'cc') emailList = this.DraftEmail.EmailCC;
      if (listType === 'bcc') emailList = this.DraftEmail.EmailBCC;

      // Process the email list
      let formattedEmails = emailList
        .flatMap(email => email.split(/[ ,;]+/)) // Split by delimiters
        .map(email => email.trim())            // Remove whitespace
        .filter(Boolean);                      // Remove empty strings

      // Remove duplicates and limit to 50 emails
      const uniqueEmails = [...new Set(formattedEmails)].slice(0, 50);

      // Assign the processed list back to the appropriate property
      if (listType === 'to') {
        this.selectedEmail = uniqueEmails;
        this.updateEmailList()
      }
      if (listType === 'cc') this.DraftEmail.EmailCC = uniqueEmails;
      if (listType === 'bcc') this.DraftEmail.EmailBCC = uniqueEmails;
    },
    removeEmail(removedEmail,listType) {
      // Determine the appropriate list based on the list type
      let emailList = [];
      if (listType === 'to') emailList = this.selectedEmail;
      if (listType === 'cc') emailList = this.DraftEmail.EmailCC;
      if (listType === 'bcc') emailList = this.DraftEmail.EmailBCC;

      // Remove the selected email from the list
      emailList = emailList.filter((email) => email !== removedEmail);

      // Update the email list back to the original variable
      if (listType === 'to') {
        this.selectedEmail = emailList;
        this.updateEmailList()
      }
      if (listType === 'cc') this.DraftEmail.EmailCC = emailList;
      if (listType === 'bcc') this.DraftEmail.EmailBCC = emailList;
    },
    updateEmailList(){   
    this.DraftEmail.EmailTo = this.selectedEmail
    },
    changeSelectedEmail(email){
      var primaryEmail = email.value;
    this.DraftEmail.EmailTo = []
    this.DraftEmail.EmailTo.push(primaryEmail)
  
    },
    closeEmailDialogue() {
      this.showEmailDialog = false;
      this.emtpyEmailToError = false;
      this.selectedDocuments = [];
      this.DraftEmail= {
        SenderAccount: "noreply@shipldi.com",
        Subject: "",
        Body: "",
        IsHtml: true,
        FromAccount: "noreply@shipldi.com",
        EmailTo: [],
        EmailCC: [],
        EmailBCC: [],
        } 
        this.attachments=[]               
    },
    areAllEmailsValid(emails) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emails.every(email => emailRegex.test(email));
    },
    async sendEmail() {
        const allEmails = this.type!='RCON'? [
          ...this.selectedEmail,
          ...this.DraftEmail.EmailCC,
          ...this.DraftEmail.EmailBCC,
        ]:[
          ...this.DraftEmail.EmailCC,
          ...this.DraftEmail.EmailBCC,
        ];

        if (!this.areAllEmailsValid(allEmails)) {
          this.$toast.add({
            severity: "error",
            summary: "Invalid Emails",
            detail: "Some email addresses are invalid. Please correct them.",
            group: 'de'
          });
          return false;
        }
        if(this.DraftEmail.EmailTo.length == 0){
                  this.emtpyEmailToError = true;
                        this.$toast.add({
                        severity: "error",
                        summary: "error",
                        detail: "Please enter a email address",
                        group: 'de'
                        });    
                        return false;          
        }else{this.emtpyEmailToError = false;}


            if(this.mailMethod == "sendEmail"){
            this.isActive = true;

            await dataProvider.sendEmail(this.DraftEmail).then((res)=>{
                if(res)
                {
                    if(res.Success){
                        this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: "Mail sent successfully.",
                        life: 3000,
                        group: 'de'
                        });
                    }
                }
            });
            this.isActive = false;
        }

        if(this.mailMethod == "sendEmailWithDocuments"){
                    this.isActive = true;
      await dataProvider.sendEmailWithDocuments(this.DraftEmail).then((res)=>{
        if(res)
        {
            if(res.Success){
                this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: "Mail sent successfully.",
                life: 3000,
                group: 'de'
                });
            }
        }
      });
                  this.isActive = false;
    }

        if(this.mailMethod == "sendEmailWithAttachments"){
            this.isActive = true;
      await dataProvider.sendEmailWithAttachments(this.DraftEmail).then((res)=>{
        if(res)
        {
            if(res.Success){
                this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: "Mail sent successfully.",
                life: 3000,
                group: 'de'
                });
            }
        }
      });
            this.isActive = false;
    }
      this.showEmailDialog = false;
      this.emtpyEmailToError = false;
      this.selectedDocuments = [];
       this.selectedEmail = this.primaryEmail;
    },  
    openEmailfromSourceLoad(draftEmail) {
      this.DraftEmail = draftEmail;      
      this.showEmailDialog = true;
      this.mailMethod = "sendEmail";
    },    
    openEmailfromLDIAgentDocs(draftEmail, selectedDocs, attachmentsArray) {
        console.log("From LDIAgentDocs : ", selectedDocs);
        draftEmail.EmailTo = '';
      this.DraftEmail = draftEmail;
      this.attachments = attachmentsArray;
      this.selectedDocuments = selectedDocs;
      var documents = this.selectedDocuments.map((p) => p.DocumentID);
      this.DraftEmail.Documents = documents;        
      this.showEmailDialog = true;
      this.mailMethod = "sendEmailWithDocuments";
    },
    openEmailfromCarrierDocument(draftEmail, selectedDocs, attachmentsArray) {
      this.DraftEmail = draftEmail;
      this.attachments = attachmentsArray;
      this.selectedDocuments = selectedDocs;
      var documents = this.selectedDocuments.map((p) => p.DocumentID);
      this.DraftEmail.Documents = documents;  
      console.log(this.selectedDocuments);
      this.showEmailDialog = true;
      this.mailMethod = "sendEmailWithDocuments";      
    },
    openEmailfromReportViewer(draftEmail, attachmentsArray) {
      console.log("From ReportViewer : ");
      this.DraftEmail = draftEmail;
      this.DraftEmail.EmailTo = [this.selectedEmail];
      this.attachments = attachmentsArray;      
      console.log("report byte array : ", draftEmail);
      this.showEmailDialog = true;
      this.mailMethod = "sendEmailWithAttachments";      
    },    
  },
};
</script>

<style lang="scss">
.theme-dark{
  .document-email {
    .p-chips .p-chips-multiple-container{
      background: #2e2f3c !important;
      border: none !important;
      color: #c6ccd3 !important;
    }
    .p-chips .p-chips-multiple-container .p-chips-input-token input{
      color: #fff !important;
    }
  .email-form-control {
    border: 1px solid #2e2f3c;
    color: white;
    background:#2e2f3c;
    padding: 10px;
    border-radius: 1px;
    height: 30px;
    display: inline-block;
    width: 100%;
  }
  .csC8F6D76,.cs4306042E{
    color: white !important;
  }
  .documentemail-dropdown{
    background: #2e2f3c;
  }
}
}
.document-email {
  .leftMargin{
    margin-left: 5.5rem;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token{
    width: 100%!important;
    margin: top 3px !important;
    input{
      width: 100%!important;
      border:none;
      font-size: 14px !important;
      padding : 0.2rem 0.5rem !important;
    }
  }
  .p-chips .p-chips-multiple-container{
    display: table !important;
    padding: 0.2rem !important;
    height: 30px !important;
    width: 100% !important;
  }
  .p-chips{
    max-height: 200px !important;
    overflow-y: auto !important;
  }
  height: 100%;
  .chipError{
    border:1px solid #ef4d43;
  }
 .deerror {
  .email-form-control {
    border: 1px solid #ef4d43;

    .p-button {
      border: 1px solid transparent;
      border-left: 1px solid #ef4d43;
    }
  }
} 
  .email-form-control {
    border: 1px solid #cbd2d9;
    padding: 10px;
    border-radius: 1px;
    height: 30px;
    display: inline-block;
    width: 100%;
  }
  .attach-form-control {
    border: 1px solid #cbd2d9;
    padding: 4px 4px 4px 10px;
    border-radius: 1px;
    height: 30px;
    display: inline-block;
    width: 100%;
  }
  .label {
    font-weight: 400;
    width: 12ch;
    padding: 0px;
  }
  .email-docs {
    font-weight: 500;
    color: rgb(31, 1, 255);
    cursor: pointer;
  }
  .documentemail-dropdown{
      display: inline-flex;
      .p-dropdown-label{
      font-size: 14px;
      }
      .p-dropdown-label.p-placeholder{
        color: #9ca6af;
      }
  }
}
</style>