import axiosConfig from "@/api/axiosConfig.js";
import moment from 'moment';

export default class CustomerService {
  DateValidation(evt){
    let finalDate = null;
    let datetonvert=evt.value.replaceAll("/","");
   
   if(datetonvert.length>=6&&datetonvert.length<=8){
    if(datetonvert[datetonvert.length-3]=='0' && datetonvert[datetonvert.length-4]=='2'){
      let dateAr=[];
      dateAr.push(datetonvert.substring(datetonvert.length-4))
      if(datetonvert.length==7){
        let month=''
        let day=''
        if(parseInt(datetonvert[0]+datetonvert[1])>12){
          day=datetonvert.substring(1,datetonvert.length-4);
          if(parseInt(day)< 1 || parseInt(day)>31)
          {
            return null ;
          }
          month=datetonvert[0]
          if(parseInt(month)< 1 || parseInt(month)>12)
          {
            return null ;
          }
        }
        else{
          day=datetonvert[2]
          if(parseInt(day)< 1 || parseInt(day)>31)
          {
            return null ;
          }
          month=datetonvert[0]+datetonvert[1]
          if(parseInt(month)< 1 || parseInt(month)>12)
          {
            return null ;
          }
          
        }
      dateAr.push(day)
      dateAr.push(month);
      dateAr.reverse()
      finalDate = new Date(dateAr.join("/"));
      return !finalDate || finalDate.toString().includes("NaN") || finalDate.toString().includes("Invalid") ? null : finalDate;

      }
      if(datetonvert.length==6){
      let month=''
      let day=''
      day = datetonvert[1] ;
      if(parseInt(day)< 1 || parseInt(day)>31)
      {
         return null ;
      }
      month = datetonvert[0];
      if(parseInt(month)< 1 || parseInt(month)>12)
      {
         return null ;
      }
      dateAr.push(day)
      dateAr.push(month);
      dateAr.reverse()
      finalDate = new Date(dateAr.join("/"));
     return !finalDate || finalDate.toString().includes("NaN") || finalDate.toString().includes("Invalid") ? null : finalDate;
      }
      if(datetonvert.length==8){
      let month=''
      let day=''
      day = datetonvert.substring(2,datetonvert.length-4);
      if(parseInt(day)< 1 || parseInt(day)>31)
      {
          return null ;
      }
      month = datetonvert.substring(0,2);
      if(parseInt(month)< 1 || parseInt(month)>12)
      {
          return null ;
      }
      dateAr.push(day)
      dateAr.push(month);
      dateAr.reverse()
      finalDate = new Date(dateAr.join("/"));
      return !finalDate || finalDate.toString().includes("NaN") || finalDate.toString().includes("Invalid") ? null : finalDate;

      }
    }
    return null
   }
   return null;
  }
  DatetoMMDDYYYYHHMM(date) {
    var dateObj = new Date(date);
    var hours =
      dateObj.getHours() < 10 ? "0" + dateObj.getHours() : dateObj.getHours();
    var minutes =
      dateObj.getMinutes() < 10 ? "0" + dateObj.getMinutes() : dateObj.getMinutes();
    
    var fullldate = this.DateToYYMMDD(date);
    var finaldate = fullldate + " " + hours + ":" + minutes;
    return finaldate;
  }
  TimeValidation(time,timeFormat){
    if(time&&!time.includes(":")){
      if(time.includes("Pm")||time.includes("pM")||time.includes("PM")||time.includes("pm")||time.includes("Am")||time.includes("aM")||time.includes("AM")||time.includes("am")){
        if(time.includes("Pm")||time.includes("pM")||time.includes("PM")||time.includes("pm")){
          time=moment(time,["h:mm"]).format("HH:mm");
          time=time+" PM";
        }
        if(time.includes("Am")||time.includes("aM")||time.includes("AM")||time.includes("am")){
          time=moment(time,["h:mm"]).format("HH:mm");
          time=time+" AM";
        }
      }
      else{
        if(!time.includes(":")&&time.length==3){
        time="0"+time;
        time=this.StringToTime(time);}
        else{
       time=moment(time, ["h:mm A"]).format("HH:mm")}
      }
      time=time=="00:00"?"12:00AM":time;
    }
    else{
      time=moment(time, ["h:mm A"]).format("HH:mm");
    }
    time= time && time!="Invalid date"? timeFormat == "12hrs"?this.tConvert(time):moment(time, ["h:mm A"]).format("HH:mm") :"";
    return time;
  }
  formatDate(value) {
    if (value) {
      return moment(String(value)).format("MM/DD/YYYY");
    }
  }
  formatTime(value) {
    if (value && typeof value !== "string") {
      return moment(String(value)).format("h:mm A");
    } else {
      return value;
    }
  }
  format24HrTime(value) {
    if (value && typeof value !== "string") {
      return moment(String(value)).format("HH:mm");
    } else {
      return value;
    }
  }
  DatetoHHMM(date) {
    var dateObj = new Date(date);
    var hours =
      dateObj.getHours() < 10 ? "0" + dateObj.getHours() : dateObj.getHours();
    var minutes =
      dateObj.getMinutes() < 10 ? "0" + dateObj.getMinutes() : dateObj.getMinutes();

    var finaltime = hours + ":" + minutes;
    return finaltime;
  }
  StringTimeToDateTime(dateStr){
      let [hour, minute] = dateStr.split(':');
      hour = parseInt(hour);
      if (hour === 12) {
        hour = 0;
      }
      if (dateStr.includes('PM')) {
        hour += 12;
      }
      let dateObj = new Date();
      dateObj.setHours(hour);
      dateObj.setMinutes(parseInt(minute));
      return dateObj;
  }
  DateToYYMMDD(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }

    HHMMToHoursMinute(hrsmins) {
        hrsmins = hrsmins.trim();
        var hhmm = hrsmins.split(":");

        if (hhmm[0].length < 2)
        {
            hhmm[0] = "0" + hhmm[0];
    }
        if (hhmm[1] !== undefined) {
            if (hhmm[1].length < 2) { hhmm[1] = "0" + hhmm[1]; }
        }

    return [hhmm[0], hhmm[1]].join(":");
  }
  StringToTime(hrsmins) {
    hrsmins = hrsmins.trim();
    var hhmm = [];
    hhmm[0] = hrsmins[0] + hrsmins[1];
    hhmm[1] = hrsmins[2] + hrsmins[3];
    return [hhmm[0], hhmm[1]].join(":");      
  }  
  tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }
  
  shippingTimeConversion(date) {
    if (date != null && date != "") {
      if (date != "1753-01-01T00:00:00") {
        var dateConv = new Date(date);

        var newDate = new Date(dateConv.getTime() + dateConv.getTimezoneOffset() * 60 * 1000);
        var offset = dateConv.getTimezoneOffset() / 60;
        var hours = dateConv.getHours();
        newDate.setHours(hours - offset);

        if (this.getHourFormat != "24hrs") {
          let timeFormat = "12hrs";
          let time = this.DatetoHHMM(newDate);
          let finaltime = this.TimeValidation(time, timeFormat);
          return finaltime;
        } else {
          let finaltime = this.DatetoHHMM(newDate);
          return finaltime;
        }
      }
    }
  }

  async GetMyLoads(params) {
    const event = new CustomEvent('GetMyLoads');
    var result = [];
    await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/myLoads`, {
        OriginalEvent: {
          Page: params.lazyEvent.page,
          First: params.lazyEvent.first,
          Rows: params.lazyEvent.rows,
          PageCount: params.lazyEvent.pageCount,
        },
        PortalId: params.lazyEvent.PortalId,
        First: params.lazyEvent.first,
        Page: params.lazyEvent.page,
        Rows: params.lazyEvent.rows,
        SortOrder:
          params.lazyEvent.sortOrder == null ? 1 : params.lazyEvent.sortOrder,
        SortField: params.lazyEvent.sortField,
        Filters: params.lazyEvent.filters,
        PageCount: params.lazyEvent.pageCount,
        MultiSortMeta: {},
        ProxyPortals: params.lazyEvent.ProxyPortals,
        SelectFields: params.lazyEvent.SelectFields,
      })

      .then((res) => {
        result = res.data;
        if (!params?.dontDispatchGetMyLoadsEvent) {
          window.dispatchEvent(event);
        }
      });
    return result;
  }

  async GetAllSelectedMyLoads(params) {
    var result = [];
    await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/TrackLoads/getAllCheckedMyLoads`, {
        Filters: {
          LoadID: {
            value: params.LoadID.value,
            MatchMode: params.LoadID.matchMode,
          },
          Carrier: {
            Value: params.Carrier.value,
            MatchMode: params.Carrier.matchMode,
          },
          Customer: {
            Value: params.Customer.value,
            MatchMode: params.Customer.matchMode,
          },
          Origin: {
            Value: params.Origin.value,
            MatchMode: params.Origin.matchMode,
          },
          Destination: {
            Value: params.Destination.value,
            MatchMode: params.Destination.matchMode,
          },
          Status: {
            Value: params.Status.value,
            MatchMode: params.Status.matchMode,
          },
          Truckload: {
            Value: params.Truckload.value,
            MatchMode: params.Truckload.matchMode,
          },
        },
        ProxyPortals: params.ProxyPortals,
        PortalId: params.PortalId,
      })

      .then((res) => {
        result = res.data;
      });
    return result;
  }

  async GetMySourceLoads(params) {
    console.log("GetMySourceLoads params", params);
    var result = [];
    await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/SourceLoad/SourceLoadCarrierDomicile`, {
        OriginalEvent: {
          Page: params.page,
          First: params.first,
          Rows: params.rows,
          PageCount: params.pageCount,
        },
        PortalId: params.PortalId,
        LoadID: params.LoadID,
        CustomerID: params.CustomerID,
        PickupDate: params.PickupDate,
        DropDate: params.DropDate,
        First: params.first,
        Page: params.page,
        Rows: params.rows,
        SortOrder:
          params.sortOrder == null ? 1 : params.sortOrder,
        SortField: params.sortField,
        Filters: {
          CarrierName: {
            value: params.filters.CarrierName.value,
            MatchMode: params.filters.CarrierName.matchMode,
          },
          CarrierMCNum: {
            Value: params.filters.CarrierMCNum.value,
            MatchMode: params.filters.CarrierMCNum.matchMode,
          },
          DOTNumber: {
            Value: params.filters.DOTNumber.value,
            MatchMode: params.filters.DOTNumber.matchMode,
          },
          USDOTNumber: {
            Value: params.filters.USDOTNumber.value,
            MatchMode: params.filters.USDOTNumber.matchMode,
          },
          LoadType: {
            Value: params.filters.LoadType.value,
            MatchMode: params.filters.LoadType.matchMode,
          },
        },
        PageCount: params.pageCount,
        MultiSortMeta: {},
        USPSCarriersOnly: params.USPSCarriersOnly
      })

      .then((res) => {
        result = res.data;
      });
    return result;
  }

  async GetMyFavoriteCarriers(params) {
    var result = [];
    await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/SourceLoad/SourceLoadFavoriteCarrier`, {
        OriginalEvent: {
          Page: params.page,
          First: params.first,
          Rows: params.rows,
          PageCount: params.pageCount,
        },
        PortalId: params.PortalId,
        LoadID: params.LoadID,
        CustomerID: params.CustomerID,
        PickupDate: params.PickupDate,
        DropDate: params.DropDate,
        First: params.first,
        Page: params.page,
        Rows: params.rows,
        SortOrder:
          params.sortOrder == null ? 1 : params.sortOrder,
        SortField: params.sortField,
        Filters: {
          CarrierName: {
            Value: params.filters.CarrierName.value,
            MatchMode: params.filters.CarrierName.matchMode,
          },
          CarrierMCNum: {
            Value: params.filters.CarrierMCNum.value,
            MatchMode: params.filters.CarrierMCNum.matchMode,
          },
          DOTNumber: {
            Value: params.filters.DOTNumber.value,
            MatchMode: params.filters.DOTNumber.matchMode,
          },
          USDOTNumber: {
            Value: params.filters.USDOTNumber.value,
            MatchMode: params.filters.USDOTNumber.matchMode,
          },
          LoadType: {
            Value: params.filters.LoadType.value,
            MatchMode: params.filters.LoadType.matchMode,
          },
        },
        PageCount: params.pageCount,
        MultiSortMeta: {},
        USPSCarriersOnly: params.USPSCarriersOnly
      })

      .then((res) => {
        result = res.data;
      });
    return result;
  }

  async GetMySourceLoadCarrierDelivery(params) {
    var result = [];
    await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/SourceLoad/SourceLoadCarrierDelivery`, {
        SelectFields: "",
        OriginalEvent: {
          Page: params.page,
          First: params.first,
          Rows: params.rows,
          PageCount: params.pageCount,
        },
        PortalId: params.PortalId,
        LoadID: params.LoadID,
        CustomerID: params.CustomerID,
        PickupDate: params.PickupDate,
        DropDate: params.DropDate,
        EquipmentTypeID: params.EquipmentTypeID,
        First: params.first,
        Page: params.page,
        Rows: params.rows,
        SortOrder:
          params.sortOrder == null ? 1 : params.sortOrder,
        SortField: params.sortField,
        Filters: {
          PickupCity: {
            value: params.filters.PickupCity.value,
            MatchMode: params.filters.PickupCity.matchMode,
          },
          PickupState: {
            Value: params.filters.PickupState.value,
            MatchMode: params.filters.PickupState.matchMode,
          },
          PickupDate: {
            Value: params.filters.PickupDate.value,
            MatchMode: params.filters.PickupDate.matchMode,
          },
          Radius: {
            Value: params.filters.Radius.value,
            MatchMode: params.filters.Radius.matchMode,
          },
          LoadType: {
            Value: params.filters.LoadType.value,
            MatchMode: params.filters.LoadType.matchMode,
          },
        },
        PageCount: params.pageCount,
        CarrierSmartSearch:params.CarrierSmartSearch,
        MultiSortMeta: {},
        USPSCarriersOnly: params.USPSCarriersOnly
      })

      .then((res) => {
        result = res.data;
      });
    return result;
  }
  async GetMySourceLoadCarrierLoadBoard(params) {
    var result = [];
    await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/SourceLoad/SourceLoadLoadBoard`, {
        SelectFields: "",
        OriginalEvent: {
          Page: params.page,
          First: params.first,
          Rows: params.rows,
          PageCount: params.pageCount,
        },
        LoadID: params.LoadID,
        CustomerID: params.CustomerID,
        PickupDate: params.PickupDate,
        DropDate: params.DropDate,
        EquipmentID: params.EquipmentID,
        PickupCity: params.PickupCity,
        PickupState: params.PickupState,
        DestinationCity: params.DestinationCity,
        DestinationState: params.DestinationState,
        PortalId: params.PortalId,
        First: params.first,
        Page: params.page,
        Rows: params.rows,
        SortOrder:
          params.sortOrder == null ? 1 : params.sortOrder,
        SortField: params.sortField,
        Filters: {
          Expedited: {
            Value: params.filters.Expedited.value,
            MatchMode: params.filters.Expedited.matchMode,
          },
          Hazardous: {
            Value: params.filters.Hazardous.value,
            MatchMode: params.filters.Hazardous.matchMode,
          },
          PalletExchange: {
            Value: params.filters.PalletExchange.value,
            MatchMode: params.filters.PalletExchange.matchMode,
          },
          Tarps: {
            Value: params.filters.Tarps.value,
            MatchMode: params.filters.Tarps.matchMode,
          },
          Team: {
            Value: params.filters.Team.value,
            MatchMode: params.filters.Team.matchMode,
          },
          Age: {
            Value: params.filters.Age.value,
            MatchMode: params.filters.Age.matchMode,
          },

          Radius: {
            Value: params.filters.Radius.value,
            MatchMode: params.filters.Radius.matchMode,
          },
          LoadType: {
            Value: params.filters.LoadType.value,
            MatchMode: params.filters.LoadType.matchMode,
          },
        },
        PageCount: params.pageCount,
        CarrierSmartSearch:params.CarrierSmartSearch,
        MultiSortMeta: {},
        USPSCarriersOnly: params.USPSCarriersOnly
      })

      .then((res) => {
        result = res.data;
      });
    return result;
  }
  async GetMySourceLoadCarrierHeadquarter(params) {
    var result = [];
    await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/SourceLoad/SourceLoadCarrierHeadquarter`, {
        SelectFields: "",
        OriginalEvent: {
          Page: params.page,
          First: params.first,
          Rows: params.rows,
          PageCount: params.pageCount,
        },
        EquipmentTypeID: params.EquipmentTypeID,
        PortalId: params.PortalId,
        LoadID: params.LoadID,
        CustomerID: params.CustomerID,
        PickupDate: params.PickupDate,
        DropDate: params.DropDate,
        First: params.first,
        Page: params.page,
        Rows: params.rows,
        SortOrder:
          params.sortOrder == null ? 1 : params.sortOrder,
        SortField: params.sortField,
        Filters: {
          City: {
            value: params.filters.City.value,
            MatchMode: params.filters.City.matchMode,
          },
          State: {
            Value: params.filters.State.value,
            MatchMode: params.filters.State.matchMode,
          },

          Radius: {
            Value: params.filters.Radius.value,
            MatchMode: params.filters.Radius.matchMode,
          },
          LoadType: {
            Value: params.filters.LoadType.value,
            MatchMode: params.filters.LoadType.matchMode,
          },
        },
        PageCount: params.pageCount,
        CarrierSmartSearch:params.CarrierSmartSearch,
        MultiSortMeta: {},
        USPSCarriersOnly: params.USPSCarriersOnly
      })

      .then((res) => {
        result = res.data;
      });
    return result;
  }
  async GetMySourceLoadHistory(params) {
    var result = [];
    await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/SourceLoad/SourceLoadHistory`, {
        SelectFields: "",
        OriginalEvent: {
          Page: params.page,
          First: params.first,
          Rows: params.rows,
          PageCount: params.pageCount,
        },
        PortalId: params.PortalId,
        LoadID: params.LoadID,
        CustomerID: params.CustomerID,
        PickupDate: params.PickupDate,
        DropDate: params.DropDate,
        EquipmentTypeID: params.EquipmentTypeID,
        First: params.first,
        Page: params.page,
        Rows: params.rows,
        SortOrder:
          params.sortOrder == null ? 1 : params.sortOrder,
        SortField: params.sortField,
        Filters: {
          SearchMonths: {
            value: params.filters.SearchMonths.value,
            MatchMode: params.filters.SearchMonths.matchMode,
          },
          OriginCity: {
            value: params.filters.OriginCity.value,
            MatchMode: params.filters.OriginCity.matchMode,
          },
          OriginState: {
            Value: params.filters.OriginState.value,
            MatchMode: params.filters.OriginState.matchMode,
          },
          OriginRadius: {
            Value: params.filters.OriginRadius.value,
            MatchMode: params.filters.OriginRadius.matchMode,
          },
          DestinationCity: {
            value: params.filters.DestinationCity.value,
            MatchMode: params.filters.DestinationCity.matchMode,
          },
          DestinationState: {
            Value: params.filters.DestinationState.value,
            MatchMode: params.filters.DestinationState.matchMode,
          },
          DestinationRadius: {
            Value: params.filters.DestinationRadius.value,
            MatchMode: params.filters.DestinationRadius.matchMode,
          },
          ShowAll: {
            Value: params.filters.ShowAll.value,
            MatchMode: params.filters.ShowAll.matchMode,
          },
          FullTruckload: {
            Value: params.filters.FullTruckload.value,
            MatchMode: params.filters.FullTruckload.matchMode,
          },
          LTLPartial: {
            Value: params.filters.LTLPartial.value,
            MatchMode: params.filters.LTLPartial.matchMode,
          },
          CustomerID: {
            Value: params.filters.CustomerID.value,
            MatchMode: params.filters.CustomerID.matchMode,
          },
          PortalId: {
            Value: params.filters.PortalId.value,
            MatchMode: params.filters.PortalId.matchMode,
          },
          LoadType: {
            Value: params.filters.LoadType.value,
            MatchMode: params.filters.LoadType.matchMode,
          },
        },
        CarrierSmartSearch:params.CarrierSmartSearch,
        PageCount: params.pageCount,
        MultiSortMeta: {},
        USPSCarriersOnly: params.USPSCarriersOnly
      })

      .then((res) => {
        result = res.data;
      });
    return result;
  }

  async GetPayments(params, carrierID, timezoneOffset) {
    var result = [];
    await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/carrierProfilePayment`, {
        OriginalEvent: {
          Page: params.lazyEvent.page,
          First: params.lazyEvent.first,
          Rows: params.lazyEvent.rows,
          PageCount: params.lazyEvent.pageCount,
        },
        First: params.lazyEvent.first,
        Page: params.lazyEvent.page,
        Rows: params.lazyEvent.rows,
        SortOrder:
          params.lazyEvent.sortOrder == null ? 1 : params.lazyEvent.sortOrder,
        SortField: params.lazyEvent.sortField,
        CarrierID: carrierID,
        TimezoneOffset: timezoneOffset,
        Filters: {
          LoadID: {
            value: params.lazyEvent.filters.LoadID.value,
            MatchMode: params.lazyEvent.filters.LoadID.matchMode,
          },
          Status: {
            Value: params.lazyEvent.filters.Status.value,
            MatchMode: params.lazyEvent.filters.Status.matchMode,
          },
          Origin: params.lazyEvent.filters.Origin,
          ShipDateFirst: {
            Value: params.lazyEvent.filters.ShipDateFirst.value,
            MatchMode: params.lazyEvent.filters.ShipDateFirst.matchMode,
          },
          ShipDateSecond: {
            Value: params.lazyEvent.filters.ShipDateSecond.value,
            MatchMode: params.lazyEvent.filters.ShipDateSecond.matchMode,
          },
          Destination: params.lazyEvent.filters.Destination,
          DropDateFirst: {
            Value: params.lazyEvent.filters.DropDateFirst.value,
            MatchMode: params.lazyEvent.filters.DropDateFirst.matchMode,
          },
          DropDateSecond: {
            Value: params.lazyEvent.filters.DropDateSecond.value,
            MatchMode: params.lazyEvent.filters.DropDateSecond.matchMode,
          },
          PayDate: {
            Value: params.lazyEvent.filters.PayDate.value,
            MatchMode: params.lazyEvent.filters.PayDate.matchMode,
          },
        },
        PageCount: params.lazyEvent.pageCount,
        MultiSortMeta: {},
      })

      .then((res) => {
        result = res.data;
      });
    return result;
  }

  async GetHistoryGridData(params, carrierID, timezoneOffset) {
    var result = [];
    await axiosConfig
      .post(`${process.env.VUE_APP_API_WEB_URL}/Carrier/carrierProfileHistoryTabInfo`, {
        OriginalEvent: {
          Page: params.lazyEvent.page,
          First: params.lazyEvent.first,
          Rows: params.lazyEvent.rows,
          PageCount: params.lazyEvent.pageCount,
        },
        First: params.lazyEvent.first,
        Page: params.lazyEvent.page,
        Rows: params.lazyEvent.rows,
        SortOrder:
          params.lazyEvent.sortOrder == null ? 1 : params.lazyEvent.sortOrder,
        SortField: params.lazyEvent.sortField,
        CarrierID: carrierID,
        TimezoneOffset: timezoneOffset,
        Filters: {
          LoadTypeID: params.lazyEvent.filters.LoadType,
          PickupDate: {
            Value: params.lazyEvent.filters.PickupDate.value,
            MatchMode: params.lazyEvent.filters.PickupDate.matchMode,
          },
          Origin:params.lazyEvent.filters.Origin,
          DeliveryDate: {
            Value: params.lazyEvent.filters.DeliveryDate.value,
            MatchMode: params.lazyEvent.filters.DeliveryDate.matchMode,
          },
          Destination:params.lazyEvent.filters.Destination,
          EquipTypeID: params.lazyEvent.filters.EquipType,
        },
        PageCount: params.lazyEvent.pageCount,
        MultiSortMeta: {},
      })

      .then((res) => {
        result = res.data;
      });
    return result;
  }
  SetMyLoadsFilterObjectProperties(){
    return {
      LoadID: [],
      CustomerID: [],
      CustomerName: [],
      PickupLocation: [],
      DeliveryLocation: [],
      Type: [],
      EquipmentType: [],
      Carrier: [],
      Status: [],
      BillOfLading: [],
      BillingReferenceNumber: [],
      CarrierEmail: [],
      CarrierProNum: [],
      CarrierMCNum: [],
      DOTNumber: [],
      USDOTNumber: [],
      Commodity: [],
      CoveredByUsername: [],
      CreatedByID: [],
      CreatedBy: [],
      DeliveryDate: [],
      DeliveryDateTime: [],
      Dispatcher: [],
      DriverName: [],
      DriverPhone: [],
      DropAppointmentsMade: [],
      LockedByUsernane: [],
      Mileage: [],
      OnHoldEndDate: [],
      OnHoldStartDate: [],
      OperationsTeam : [],
      PONum : [],
      PayRange: [],
      Phone: [],
      PickupAppointmentsMade: [],
      PickupDate: [],
      PickupDateTime: [],
      PickupNo: [],
      PickupStatus: [],
      SignedRateCon: [],
      TotAP: [],
      TotAR: [],
      Tracking: [],
      Weight: [],
      Truckload: [],
      OriginCity: [],
      OriginState: [],
      DestinationCity: [],
      DestinationState: [],
      PickupLocationName: [],
      DropLocationName: [],
      OperationsManager: [],
      HandlingUnitType : [],
      HandlingUnitQuantity: [],
      ShipmentID : [],
      CarrierPickup : [],
      PortalName : [],
      FieldOne : [],
      FieldTwo : [],
      FieldThree: [],
      AccountManager: [],
      ProxyPortals: [],
      PortalId: [],
    }
  }
  formatToDecimal(n) {
    if (n < 0.01) {
      n = (Math.ceil(n * 100) / 100);
    }
    return n.toFixed(2);
  }
  FTLThreshold(LoadMTInfoDate, LoadMTInfoTime) {
    let loadMTInfoDateTime = this.getMTInfoDateTime(LoadMTInfoDate, LoadMTInfoTime);
    let duration = parseInt((loadMTInfoDateTime.getTime() - (new Date()).getTime()) / 60000);
    //if(duration>=0 && duration<=60)
    if (duration <= 60)
      return '2';
    else if (duration <= 90 && duration > 60)
      return '1';
    else
      return '0';
  }
  LTLThreshold(pickupDate) {
    let threshold = 0;
    if (((new Date(pickupDate)).getFullYear() == (new Date).getFullYear()) && (this.getDayOfYear(new Date(pickupDate)) == this.getDayOfYear(new Date)))
      threshold = 1;
    else if (((new Date(pickupDate)).getFullYear() == (new Date).getFullYear()) && (this.getDayOfYear(new Date(pickupDate)) < this.getDayOfYear(new Date)))
      threshold = 2;
    else
      threshold = 0;
    return threshold;
  }
  getDayOfYear(dateInput) {
    const timestamp1 = Date(
      dateInput.getFullYear(),
      dateInput.getMonth(),
      dateInput.getDate(),
    );
    const timestamp2 = Date(dateInput.getFullYear(), 0, 0);

    const differenceInMilliseconds = timestamp1 - timestamp2;

    const differenceInDays = differenceInMilliseconds / 1000 / 60 / 60 / 24;

    return differenceInDays;
  }
  getMTInfoDateTime(LoadMTInfoDate, LoadMTInfoTime) {
    let TimeHr24 = LoadMTInfoTime;
    if (LoadMTInfoTime != null && (LoadMTInfoTime?.toLowerCase().includes('am') || LoadMTInfoTime?.toLowerCase().includes('pm'))) {
      if (LoadMTInfoTime != null)
        TimeHr24 = this.ConvertTimeStringTo24Hours(LoadMTInfoTime?.toLowerCase());
      else
        TimeHr24 = "00:00:00"
    }
    let splitDateTime = LoadMTInfoDate?.split('T');
    splitDateTime[1] = TimeHr24?.trim();
    return new Date(splitDateTime[0] + 'T' + splitDateTime[1]);
  }
  ConvertTimeStringTo24Hours(timeString) {

    if (timeString.match(/pm$/)) {
      let timeStr = timeString.replace('pm', '');
      let splitTime = timeStr.split(':');
      let hours = parseInt(splitTime[0]) + 12;
      if (hours == 24)
        hours = 0;
      let hrs = this.pad(hours, 2);
      let minutes = splitTime[1].trim();
      let seconds = '00';
      return hrs + ':' + minutes + ':' + seconds;
    } else {

      let timeStr = timeString.replace('am', '');
      let splitTime = timeStr.split(':');
      let hrs = this.pad(parseInt(splitTime[0]), 2);
      let minutes = splitTime[1].trim();
      let seconds = '00';
      return hrs + ':' + minutes + ':' + seconds;
    }
  }
  pad(num, size) {
    return (Math.pow(10, size) + ~~num).toString().substring(1);
  }
  formatRowData(rows,statusDisplayDisabled,timeFormat) {

    rows.forEach((element) => {
      if (element) {
        if (element.IsFullTruckLoad) {
          // let threshold = 0;
          // if (element.LoadMTInfoDate != null) {
            // threshold = this.FTLThreshold(element.LoadMTInfoDate, element.LoadMTInfoTime);
          // }
          // let statusDisplay = statusDisplayDisabled.value ? null : this.getStatusDisplay(threshold, element.Truckload, element.Status, element.DeliveryDate);
          // element.StatusDisplay = statusDisplay;
        }
        else {
          this.LTLThreshold(element.PickupDate);
        }
        if (element.OnHoldEndDate) {
          element.OnHoldEndDacustomerService.DateToYYMMDD(
            new Date(element.OnHoldEndDate)
          );
        }
        if (element.OnHoldStartDate) {
          element.OnHoldStartDate = this.DateToYYMMDD(
            new Date(element.OnHoldStartDate)
          );
        }
        if (element.LoadDate) {
          element.LoadDate = this.DateToYYMMDD(
            new Date(element.LoadDate)
          );
        }
        if (element.PickupDate) {
          element.PickupDate = this.DateToYYMMDD(
            new Date(element.PickupDate)
          );
        }
        if (element.DeliveryDate) {
          element.DeliveryDate = this.DateToYYMMDD(
            new Date(element.DeliveryDate)
          );
        }
        if (element.SignedRateCon == 1) {
          element.SignedRateCon = "Yes";
        }
        else if (element.SignedRateCon == 0) {
          element.SignedRateCon = "No";
        }
        else {
          element.SignedRateCon = "";
        }
        if (element.TotAR) {
          element.TotAR = this.formatToDecimal(element.TotAR);
        }
        if (element.TotAP) {
          element.TotAP = this.formatToDecimal(element.TotAP);
        }
        this.puTimeDlTimeConversion(element,timeFormat);
      }
    })
  }
  puTimeDlTimeConversion(element,timeFormat) {

    if (!element?.PickupDateTime?.includes('/') && !element?.DeliveryDateTime?.includes('/')) {
        if (element?.PickupDateTime?.includes('-')) {
            let timearray = element?.PickupDateTime?.split('-');
            timearray[0] = this.TimeValidation(timearray[0], timeFormat);
            timearray[1] = this.TimeValidation(timearray[1], timeFormat);
            element.PickupDateTime = timearray[0] + ' - ' + timearray[1]
        }
        else {
            element.PickupDateTime = this.TimeValidation(element?.PickupDateTime, timeFormat);
        }
        if (element?.DeliveryDateTime?.includes('-')) {
            let timearray = element?.DeliveryDateTime?.split('-');
            timearray[0] = this.TimeValidation(timearray[0], timeFormat);
            timearray[1] = this.TimeValidation(timearray[1], timeFormat);
            element.DeliveryDateTime = timearray[0] + ' - ' + timearray[1]
        }
        else {
            element.DeliveryDateTime = this.TimeValidation(element?.DeliveryDateTime, timeFormat);
        }
    }
    else {
        element.PickupDateTime = "";
        element.DeliveryDateTime = "";
    }
  }
}
